import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';
import { PublicModule } from '../public.module';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { HttpParams, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MainService {
  public user: any;
  public empresa: any;
  public headers: Headers;
  public baseUrl: String = environment.urlBase;
  constructor(
    private httpClient: HttpClient,
    public http: Http,
    private authService: AuthService,
    private router: Router,
    private toastrService: NbToastrService,
  ) {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');
    this.onSetBearAuthorization()
    this.user = JSON.parse(localStorage.getItem('usuario')) || {};
  }

  /**
   * On set authorization bear
   */
  onSetBearAuthorization () {
    this.headers.append('Authorization', this.authService.getToken());    
  }

  /**
   * API Generico PUT
   * @param {string} api route: api/componente/id
   * @param {any} data propiedades a actualizar
   * @return {Observable<any>} respuesta asincrónica
   */
  /**
   * API Generico GET
   * @param {string} api route: api/componente
   * @return {Observable<any>} respuesta asincrónica
   */
  get({ api }: { api: String }) {
    return this.http.get(`${this.baseUrl}${api}`, { headers: this.headers }).pipe(map((res) => res.json()));
  }

  getWithParams(api: string, params: { key:string, value:string }) {
    let queryParams = new HttpParams().append(params.key, params.value);    
    return this.httpClient.get(`${this.baseUrl}${api}`, { params: queryParams });
  }

  /**
   * Get sending by queryParam values 
   * @param param 
   * @returns 
   */
  getAdmin(api: string) {
    let queryParams = new HttpParams().append('platformType', 'admin');
    return this.httpClient.get(`${this.baseUrl}${api}`, { params: queryParams });
  }

  /**
   * API Generico POST
   * @param {string} api route: api/componente
   * @param {any} data objeto a persistir
   * @return {Observable<any>} respuesta asincrónica
   */
  post({ api, data }: { api: String; data: any }) {
    return this.http
      .post(`${this.baseUrl}${api}`, JSON.stringify(data), {
        headers: this.headers,
      })
      .pipe(map((res) => res.json()));
  }

  /**
   * API Generico DELETE
   * @param {string} api route: api/componente/id
   * @return {Observable<any>} respuesta asincrónica
   */
  delete({ api }: { api: String }) {
    return this.http.delete(`${this.baseUrl}${api}`, { headers: this.headers }).pipe(map((res) => res.json()));
  }

  /**
   * API Generico PUT
   * @param {string} api route: api/componente/id
   * @param {any} data propiedades a actualizar
   * @return {Observable<any>} respuesta asincrónica
   */
  put({ api, data }: { api: String; data: any }) {
    return this.http
      .put(`${this.baseUrl}${api}`, JSON.stringify(data), {
        headers: this.headers,
      })
      .pipe(map((res) => res.json()));
  }

  /**
   * Opens a Nebular Dialog
   * @param  {any} dialog Reference to the template
   * containing the dialog. Must be type ElementRef or TypeReference
   * @returns void
   */
  showToastr({
    position,
    title,
    message,
    status,
  }: {
    position?: any;
    title: string;
    message?: string;
    status: any;
  }): void {
    const realPosition = position ? position : 'top-end';
    const realMessage = message ? message : '';
    const duractionMilisec = 4500;
    this.toastrService.show(`${realMessage}`, `${title}`, {
      position: realPosition,
      status,
      duration: duractionMilisec,
    });
  }

  /**
   * Handles navigation to the specified URL
   * @param {string} path -> "/index/home"
   * @return void
   */
  public routerNavigateTo({ path }: { path: string }): void {
    this.router.navigate([path]).then((fulfilled) => {
      if (!fulfilled) {
        this.showToastr({
          title: 'Invalid URL',
          status: 'basic',
        });
      }
    });
  }

  /**
   * Método POST PROMISE
   * @param {string} object ruta que se ejecutara
   * @param {object} data object con info a enviar al post
   */
  postPromise(object: String, data: any) {
    return new Promise((success, fail) => {
      this.headers = new Headers();
      this.headers.append('Content-Type', 'application/json');
      this.headers.append('Authorization', this.authService.getToken());
      this.http
        .post(this.baseUrl + '' + object, JSON.stringify(data), {
          headers: this.headers,
        })
        .pipe(map((res) => res.json()))
        .subscribe(
          (result) => {
            success(result);
          },
          (err) => {
            fail(err);
          },
        );
    });
  }
}
